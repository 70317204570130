import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/BlogPost/BlogPost.js";
import Code from '@components/Code';
import fakeNewsGradientCode from './code/fake-news-gradient.example';
import screenshot from './code/screenshot.example';
import pulumi from './code/pulumi.example';
import cr from './code/cr.example';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Operators in Kubernetes are a lot like human operators that have some kind of knowledge in a specific domain. Let’s say we want to install a stateful application like Prometheus, and we want to manage all its aspects on how to do things like how to update it, restore backups, keep it running if something happens, react and fix things, and more.`}</p>
    <p>{`With Operators, all the actions that we did manually can be encoded in Operators in Kubernetes, so the operator can handle that for you.
To understand operators, we first need to understand how Kubernetes itself handles the mechanism that operators are using, and the analogy is to a Kubernetes controller and “control theory.”`}</p>
    <p>{`A core principle of Kubernetes is “control theory.” In simple terms, The Kubernetes user declares the desired state, Then a different Kubernetes resource called a  controller comes and incrementally moves the current state toward that desired state. This process is often referred to as `}<em parentName="p">{`reconciliation`}</em>{`. For example, let’s take the replicaset-controller. This controller is responsible for ensuring the number of pods created matches the desired count set in replicas. Like the replicaset controller, we have many other controllers in the cluster running simultaneously.`}</p>
    <p>{`in pseudo code it will look something like this:`}</p>
    <Code lang="yaml" mdxType="Code">{pulumi}</Code>
    <p>{`So the first thing to understand is that the operators are basically `}<em parentName="p">{`controllers`}</em>{` with the differentiation that they have specific knowledge in a particular application or domain.`}</p>
    <p>{`For example, the Prometheus operator knows what needs to be done to upgrade the version, do backups, etc.`}</p>
    <h3>{`Custom Resource Definitions (CRD)`}</h3>
    <p>{`Operators take advantage of Custom Resource Definitions that allow us to extend the Kubernetes API.
There are core Kubernetes resources like Pod and Deployment, and the Kubernetes controllers act upon those resources, while the Operators act upon a custom resource.`}</p>
    <p>{`Let’s put everything together by creating our very own custom resource via a CRD.`}</p>
    <p>{`Let’s say we create the following file and apply it to the cluster; what will happen?`}</p>
    <Code lang="yaml" mdxType="Code">{fakeNewsGradientCode}</Code>
    <p>{`If we run kubectl get crd we should see it in the list. be aware that we still don’t have the custom resource itself - it’s only the custom resource definition, but now we can create an object with kind to be Mysql that will be the custom resource (CR)`}</p>
    <p>{`create the custom resource as the following:`}</p>
    <Code lang="yaml" mdxType="Code">{cr}</Code>
    <p>{`Now if we will run kubectl get MySQL we should get our very own custom object that we just created.`}</p>
    <blockquote>
      <blockquote parentName="blockquote">
        <p parentName="blockquote">{`Now ask yourself The following question before you continue.
We have created the custom resource. Does something need to happen ? Is something in the cluster doing magical stuff behind the scenes?`}</p>
      </blockquote>
    </blockquote>
    <p>{`No! Nothing should happen yet, as we didn’t tell the operator to act upon the custom resource we created.`}</p>
    <p>{`A Custom Resource needs a custom controller to ACT upon its presence, and combining that info with the specific knowledge for the custom resource. We get the complete concept for Operators in Kubernetes.`}</p>
    <p>{`Now, to react upon a custom resource, the custom operator requires many building blocks and boilerplate code; writing an operator from scratch is challenging. It needs to listen to events from the Kubernetes API server to know when the custom resource was created, deleted, updated, etc. It has to know about caching and informers, event handling, and more! Because of that, the Operator Framework was created, including the operator-SDK.`}</p>
    <h2>{`Using existing Operators`}</h2>
    <p>{`We can use Operators that are already created for popular applications out there like  Prometheus and kiali operators, and many operators can also be found on the website `}<a parentName="p" {...{
        "href": "https://OperatorHub.io"
      }}>{`OperatorHub.io`}</a>{` | The registry for Kubernetes Operators`}</p>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      